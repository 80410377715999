import axios from "axios";

export const getInvoiceOrders = async ({commit}) => {
    let invoiceId = window.location.pathname.match(/\/([a-zA-Z0-9-]+)$/);

    if(!(invoiceId && invoiceId.length === 2 && typeof invoiceId[1] === 'string' && invoiceId[1].length === 36)) {
        return [];
    }

    invoiceId = invoiceId.pop();

    try {
        const invoiceOrdersResponse = await axios.get(`/public-api/invoice/${invoiceId}/orders`);

        const {code, result} = invoiceOrdersResponse.data;

        if (code === 0) {
            commit('setInvoiceOrders', result);
        }

        return result
    } catch (e) {
        console.error('Fail for get invoice orders data', e);
    }
}
