<template>
  <div class="invoice-main-page" >
    <invoice-header />
    <div style="height: calc(100% - 117px); width: 100%; display: flex; align-items: start; justify-content: center">
      <invoice-container />
    </div>
<!--    <dashboard />-->
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "CheckoutMainPage",
  components: {
    InvoiceHeader: () => import(/* webpackChunkName: "entries/invoice/components/Header" */ '@/entries/invoice/components/Header.vue'),
    InvoiceContainer: () => import(/* webpackChunkName: "entries/invoice/components/Container" */ '@/entries/invoice/components/Container'),
    Dashboard: () => import(/* webpackChunkName: "entries/invoice/components/Dashboard" */ '@/entries/invoice/components/Dashboard.vue')
  },
  data() {
    return {
      updateInterval: undefined
    }
  },
  watch: {
    invoiceStatusSuccess: {
      handler(v) {
        if (!!v) {
          clearInterval(this.updateInterval)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(['toggle', 'themeStatus', 'invoice', 'lang', {
        darkTheme: state => state.themeStatus,
    }]),
    background() {
      if (!this.toggle) return {'--background': '#E7F0FF'};
      return {'--background': '#ffffff'};
    },
    invoiceStatusSuccess({ invoice }) {
      return invoice.status === 'PROCESSED' || invoice.status === 'OVERPAID'
    }
  },
  async created() {
      await this.checkOrdersForInvoice()
      await this.fetchInvoice().then((data) => {
        if (data.id && !this.invoiceStatusSuccess) {
          this.updateInvoice()
        }
      })

      this.toggleTheme();
      const queryString = window.location.search

      if (queryString) return

      const urlParams = new URLSearchParams(queryString);

      urlParams.set('theme', this.darkTheme ? 'dark' : 'light')
      urlParams.set('lang', this.lang)

      window.history.replaceState( {
          theme: this.darkTheme ? 'dark' : 'light'
      }, import.meta.env.VITE_PRODUCT_NAME, `${window.location.origin}${window.location.pathname}?${urlParams.toString()}` );
  },
  mounted() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    ...mapMutations('app', ['setTheme']),
    ...mapActions(['fetchInvoice', 'getInvoiceOrders']),
    updateInvoice() {
      this.updateInterval = setInterval(async () => {
        await this.fetchInvoice()
        await this.checkOrdersForInvoice()
      }, 5000)
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    toggleTheme() {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString);
      const urlTheme = urlParams.get('theme')
      const urlBoolean = urlTheme === 'dark'
      const productName = import.meta.env.VITE_PRODUCT_NAME
      const dark = productName !== 'Apollopayment' ? 'public-dark' : 'apollo-public-dark'
      const light = productName !== 'Apollopayment' ? 'public-light' : 'apollo-public-light'

      if (urlTheme !== null) {
        this.setTheme(urlTheme === 'dark' ? 'light' : 'dark')
        this.$store.commit('changeThemeStatus', urlBoolean);

        if (urlBoolean) document.documentElement.setAttribute("data-theme", dark);
        else document.documentElement.setAttribute("data-theme", light);
        return
      }

      const themeOsDark = window.matchMedia("(prefers-color-scheme: dark)");
      const theme = localStorage.getItem('data-theme');

      this.setTheme(theme === 'dark' ? 'light' : 'dark') //пишем тему в стор

      if (theme === dark) {
        this.$store.commit('changeThemeStatus', true);
        document.documentElement.setAttribute("data-theme", dark);

      } else if (theme === light) {
        this.$store.commit('changeThemeStatus', false);
        document.documentElement.setAttribute("data-theme", light);
      } else if (themeOsDark.matches) {
        this.$store.commit('changeThemeStatus', true);
        document.documentElement.setAttribute("data-theme", dark);
      } else {
        this.$store.commit('changeThemeStatus', false);
        document.documentElement.setAttribute("data-theme", light);
      }
    },
    async checkOrdersForInvoice() {
      await this.getInvoiceOrders()
    }
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
.main-body {
  background: var(--body-color);
}
.invoice-main-page {
  position: relative;
  height: 100vh;

  .dashboard, .not-found-page {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;

    max-height: fit-content;

    @include below_mobile {
      top: 105px;
      transform: translate(-50%, 0);
    }

    max-width: 623px!important;
  }
}
</style>
