import axios from "axios";

import {INVOICE_STATUS_ENUM} from "@/common/constants/invoices-status-enum";

export const fetchInvoice = async ({state, commit}) => {
    if (state.fetching) {
        return;
    }
    if (state.invoice.status === INVOICE_STATUS_ENUM.PROCESSED || state.invoice.status === INVOICE_STATUS_ENUM.ERROR || state.invoice.status === INVOICE_STATUS_ENUM.EXPIRED) {
        return;
    }

    commit('setFetching', true);

    let invoiceId = window.location.pathname.match(/\/([a-zA-Z0-9-]+)$/);

    if(!(invoiceId && invoiceId.length === 2 && typeof invoiceId[1] === 'string' && invoiceId[1].length === 36)) {
        commit('setFetching', false);
        commit('setFetched', true);

        return;
    }

    invoiceId = invoiceId[1];

    try {
        const invoiceResponse = await axios.get('/public-api/invoice/' + invoiceId);

        const {code, result} = invoiceResponse.data;

        if (code === 0) {
            commit('setInvoice', result);
        }

        return result
    } catch (e) {
        console.error('Fail fetch invoice data', e);
    } finally {
        commit('setFetching', false);
        commit('setFetched', true);
    }
};
