import {changeLanguage} from "./actions/changeLanguage";
import {invoicePay} from "./actions/invoicePay";
import {fetchInvoice} from "./actions/fetchInvoice";
import {closeModal, openModal} from "./actions/modal";
import {getInvoiceOrders} from "./actions/getInvoiceOrders";

export const actions = {
    fetchInvoice: fetchInvoice,
    changeLanguage: changeLanguage,
    invoicePay: invoicePay,
    openModal: openModal,
    closeModal: closeModal,
    getInvoiceOrders: getInvoiceOrders
};
