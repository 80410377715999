export const mutations = {
    setFetching(state, fetching) {
        state.fetching = fetching;
    },
    setFetched(state, value) {
        state.fetched = value;
    },

    setInvoice(state, order) {
        for(const [key, value] of Object.entries(order)) {
            state.invoice[key] = value;
        }
    },

    setInvoiceOrders(state, orders) {
        state.orders = orders
    },

    changeThemeStatus(state, payload) {
        state.themeStatus = payload;
    },

    currentCurrency(state, payload) {
        state.currency = payload;
    },

    changeLangMutations: (state, lang) => {
        state.lang = lang;
    },
    setModal(state, data = {}) {
        const {
            name = null,
            payload = {},
        } = data;

        state.openedModal = name;
        state.openedModalPayload = payload;
    }
};
